<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "会员管理" }}</template>
            <template v-slot:toolsbarLeft></template>
            <template v-slot:toolsbarRight>
                <Button type="primary" style="margin-right: 10px" @click="addBtn">新增</Button>

                <Button @click="importExcel" type="primary" icon="ios-add-circle-outline">导入</Button>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>

                <LiefengModal :title="type == 'add' ? '新增' : '修改'" :value="sendmsgStatus" @input="sendmsgStatusFn" width="700px" height="300px">
                    <template #contentarea>
                        <Form :model="sendmsgForm" :rules="sendmsgValidate" :label-width="100" ref="sendmsgForm">
                            <FormItem label="会员名称" prop="username">
                                <Input v-model.trim="sendmsgForm.username" placeholder="请填写会员名称" />
                            </FormItem>
                            <FormItem label="会员手机号" prop="mobile">
                                <Input v-model.trim="sendmsgForm.mobile" placeholder="请填写会员手机号" />
                            </FormItem>
                            <FormItem label="会员年龄">
                                <Input v-model.trim="sendmsgForm.age" type="number" placeholder="请填写会员年龄" />
                            </FormItem>
                            <FormItem label="性别">
                                <span slot="label" class="validator">性别:</span>
                                <RadioGroup v-model="sendmsgForm.sex">
                                    <Radio label="1">男</Radio>
                                    <Radio label="2">女</Radio>
                                </RadioGroup>
                            </FormItem>
                        </Form>
                    </template>
                    <template #toolsbar>
                        <Button type="info" @click="sendmsgStatusFn(false)" style="margin-right: 10px">取消</Button>
                        <Button type="primary" @click="saveSendMsg">保存</Button>
                    </template>
                </LiefengModal>

                <LiefengModal title="操作" :value="amountStatus" @input="amountStatusFn" width="500px" height="100px">
                    <template #contentarea>
                        <Form :label-width="100">
                            <FormItem label="金额" prop="amount">
                                <InputNumber v-model="amount" :min="0" />
                            </FormItem>
                        </Form>
                    </template>
                    <template #toolsbar>
                        <Button type="info" @click="amountStatusFn(false)" style="margin-right: 10px">取消</Button>
                        <Button type="primary" @click="amountSendMsg">保存</Button>
                    </template>
                </LiefengModal>
                <LiefengModal title="导入" width="40%" height="300px" :value="excelStatus" @input="excelModalData" class="export-modal">
                    <template v-slot:contentarea>
                        <Form :model="sendmsgForm" :rules="sendmsgValidate" :label-width="100" ref="sendmsgForm">
                            <FormItem label="导入">
                                <span slot="label" class="validator">导入模式:</span>
                                <RadioGroup v-model="uploadData.isAppend" @on-change="changeRadio">
                                    <Radio label="true">追加</Radio>
                                    <Radio label="false">覆盖</Radio>
                                </RadioGroup>
                            </FormItem>
                        </Form>
                        <LiefengUploadExcel
                            style="margin: 20px 0"
                            :showDownModal="{ isShow: true, downLink: './excelfiles/customer.xlsx', downName: '会员导入模板' }"
                            :headers="headers"
                            :uploadData="uploadData"
                            :action="exportAction"
                            :isShow="showExcel"
                            @success="excelSuccess"
                        ></LiefengUploadExcel>
                    </template>
                </LiefengModal>

                <CompQrCode ref="comp_qrcode"></CompQrCode>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUploadExcel from "../linehome/childrens/LiefengUploadExcel.vue"
import proxy from "@/api/proxy.js"
import CompQrCode from "../residentdatabase/components/CompQrCode.vue"

export default {
    components: { LiefengContent, LiefengTable, LiefengModal, LiefengUploadExcel, CompQrCode },
    data() {
        return {
            // table表格数据
            talbeColumns: [
                {
                    title: "名称",
                    key: "username",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "手机号",
                    key: "mobile",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "会员编号",
                    key: "userNo",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "余额",
                    key: "userAmount",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.userAmount && params.row.userAmount != "" && params.row.userAmount != 0 ? params.row.userAmount / 100 + "元" : 0)
                    },
                },
                {
                    title: "年龄",
                    key: "age",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.sex == "1" ? "男" : params.row.sex == "2" ? "女" : "未知")
                    },
                },
                {
                    title: "创建时间",
                    key: "gmtCreate",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.gmtCreate && params.row.gmtCreate != "" ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "操作",
                    width: 350,
                    align: "center",
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    justifyContent: "center",
                                },
                            },
                            [
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "primary",
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 3px",
                                        },
                                        on: {
                                            click: () => {
                                                this.amountType = "add"
                                                this.amount = ""
                                                this.amountRow = {
                                                    userNo: params.row.userNo,
                                                    companyId: params.row.companyId,
                                                    amount: this.amount,
                                                    title: "充值",
                                                    //消费类型；1:充值；2:消费
                                                    rechargeType: "1",
                                                }
                                                this.amountStatusFn(true)
                                            },
                                        },
                                    },
                                    "充值"
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "error",
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 3px",
                                        },
                                        on: {
                                            click: () => {
                                                this.amountType = "edit"
                                                this.amount = ""
                                                this.amountRow = {
                                                    userNo: params.row.userNo,
                                                    companyId: params.row.companyId,
                                                    amount: "",
                                                    title: "消费",
                                                    //消费类型；1:充值；2:消费
                                                    rechargeType: "2",
                                                }
                                                this.amountStatusFn(true)
                                            },
                                        },
                                    },
                                    "扣费"
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "primary",
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 3px",
                                        },
                                        on: {
                                            click: () => {
                                                this.type = "edit"
                                                this.sendmsgStatusFn(true)
                                                this.sendmsgForm = {
                                                    username: params.row.username,
                                                    mobile: params.row.mobile,
                                                    age: params.row.age,
                                                    sex: params.row.sex,
                                                    rechargeId: params.row.rechargeId,
                                                    companyId: params.row.companyId,
                                                }
                                            },
                                        },
                                    },
                                    "修改"
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "primary",
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 3px",
                                        },
                                        on: {
                                            click: () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "个人账单",
                                                    content: `/page#/membermanagementrecord?userId=${params.row.userNo}&companyId=${params.row.companyId}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                    },
                                    "个人账单"
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "primary",
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 3px",
                                        },
                                        on: {
                                            click: () => {
                                                this.$refs.comp_qrcode.display(
                                                    `http://h5.prod.suiyue.liefengtech.com/#/apps/shopmg/pages/pay/cusrecord?supplierId=${this.$route.query.supplierId}&promotionCode=${'P0009'}&userNo=${params.row.userNo}&orderSource=2`
                                                )
                                            },
                                        },
                                    },
                                    "二维码"
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "error",
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 3px",
                                        },
                                        on: {
                                            click: () => {
                                                this.$Modal.confirm({
                                                    title: "温馨提示",
                                                    content: "您正在删除当前会员信息，是否继续",
                                                    onOk: () => {
                                                        this.$post("/gateway/api/sycompany/pc/CompanyRechargePC/deleteRechargeUser", {
                                                            rechargeId: params.row.rechargeId,
                                                        }).then(res => {
                                                            if (res && res.code == 200) {
                                                                this.$Message.success({
                                                                    content: "操作成功",
                                                                    background: true,
                                                                })
                                                                this.getList()
                                                            }
                                                        })
                                                    },
                                                })
                                            },
                                        },
                                    },
                                    "删除"
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,

            sendmsgStatus: false,

            sendmsgForm: {
                username: "",
                mobile: "",
                age: "",
                sex: "",
                companyId: "",
            },

            sendmsgValidate: {
                username: [{ required: true, message: "请填写会员名称", trigger: "blur" }],
                mobile: [{ required: true, message: "请填写会员手机号", trigger: "blur" }],
            },
            type: "",

            amountStatus: false,

            amount: 0,

            amountType: "",
            amountRow: {},
            headers: { openId: window.sessionStorage.getItem("openId"), Authorization: window.sessionStorage.getItem("accessToken") },
            exportAction: "",
            showExcel: 0,

            excelStatus: false,

            uploadData: {
                companyId: this.$route.query.companyId,
                isAppend: "true",
            },
        }
    },
    async created() {
        this.getList()
    },
    methods: {
        changeRadio(val) {
            console.log("val", val)
        },
        excelSuccess() {
            this.excelStatus = false
            this.page = 1
            this.getList()
        },
        excelModalData(status) {
            this.excelStatus = status
            if (status) {
                this.uploadData.isAppend = "true"
            }
        },
        //导入按钮
        importExcel() {
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction = proxy["/gateway"].target + "/upload/sycompany/pc/company/importCompanyRechargeUser"
        },
        amountSendMsg() {
            if (!this.amount || this.amount == 0) {
                this.$Message.warning({
                    content: "金额不能为空",
                    background: true,
                })
                return
            }
            this.amountRow.amount = this.amount * 100
            this.userRecharge(this.amountRow)
        },
        amountStatusFn(status) {
            this.amountStatus = status
        },
        addBtn() {
            this.type = "add"
            this.sendmsgForm = {
                username: "",
                mobile: "",
                age: "",
                sex: "",
                companyId: this.$route.query.companyId,
            }

            this.sendmsgStatusFn(true)
        },
        saveSendMsg() {
            if (!this.sendmsgForm.username || this.sendmsgForm.username == "") {
                this.$Message.warning({
                    content: "姓名不能为空",
                    background: true,
                })
                return
            }
            if (!this.sendmsgForm.mobile || this.sendmsgForm.mobile == "") {
                this.$Message.warning({
                    content: "手机号不能为空",
                    background: true,
                })
                return
            }
            var url = this.type == "add" ? "/gateway/api/sycompany/pc/CompanyRechargePC/addRechargeUser" : "/gateway/api/sycompany/pc/CompanyRechargePC/updateRechargeUser"
            this.$post(
                url,
                { ...this.sendmsgForm },
                {
                    "Content-Type": "application/json",
                }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    this.sendmsgStatusFn(false)
                    this.getList()
                    return
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                    return
                }
            })
        },
        sendmsgStatusFn(status) {
            this.sendmsgStatus = status
        },
        // 分页改变接口
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList() {
            this.$get("/gateway/api/sycompany/pc/CompanyRechargePC/selectRechargeUserPage", {
                page: this.page,
                pageSize: this.pageSize,
                keyword: "",
                companyId: this.$route.query.companyId,
            }).then(res => {
                this.tableData = res.dataList
                this.total = res.maxCount
            })
        },
        userRecharge(item) {
            this.$post(
                "/gateway/api/sycompany/pc/CompanyRechargePC/userRecharge",
                {
                    ...item,
                },
                {
                    "Content-Type": "application/json",
                }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    this.amountStatusFn(false)
                    this.getList()
                    return
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>
<style lang="less">
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
</style>